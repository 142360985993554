<template >
  <div class="calendar-cont clasif">
      <div class="titulo-u-noticias">
        <h4>CLASIFICACIONES</h4>
        <!--<Sponsor :secSponsor="'NOTICIAS'" />-->
      </div>

    <div class="clasificacion-sub-header">
      <div class="logo-container" v-if="nombretorneo=='expansion'">
        <img src="../assets/logoligaexpansion.png" alt="lmx" class="liga-logo-mx">
      </div>
      <div class="logo-container" v-else-if="nombretorneo=='premier'">
        <img src="../assets/liga-premier.png" alt="lmx" class="liga-logo-mx">
      </div>
      <div class="logo-container" v-else-if="nombretorneo=='tercera'">
        <img src="../assets/LigaTDP.png" alt="lmx" class="liga-logo-mx">
      </div>
        <!--<div class="temp-sem-liga">
            <h4>Temporada 2021/2022</h4>
        <select name="" id="" class="cla-select" v-model="ligaexp">
              <option selected :value="C22">Clausura 22</option>
              <option :value="A21">Apertura 21</option>
              <option :value="C21">Clausura 21</option>
              <option :value="A20">Apertura 20</option>
              <option :value="C20">Clausura 20</option>
              <option :value="A19">Apertura 19</option>
            </select>
        </div>-->
    </div>
    <Tabs noTouch="true">
      <TabItem id="primer-equipo">
        <template #name>
          <div class="team-type-header" id="tabactiva" :class="{ activa: isActive}" @click="alturaMaxima() & changeTorneo('expansion')">
            <h4>EXPANSIÓN</h4>
          </div>
        </template>

        <TablaFirstTeam  id="primer-equipo-tab"/>
      </TabItem>
      <TabItem>
        <template #name>
          <div class="team-type-header" @click="displayTab() & changeTorneo('premier')">
            <h4>PREMIER</h4>
          </div>
        </template>

        <div class="tabla-liga">
        <div class="errore">
          <div class="en-construccion-cont">
              <img src="../assets/tarjeta-rojiza.png" alt="tarjetiza" class="tarjetiza">
              <h3 class="titulo-en-constr"><span class="red"> VUELVE EN UN RATO.</span> ESTAMOS CARGANDO LA INFORMACIÓN.</h3>
          </div>
        </div>
        </div>
      </TabItem>
      <TabItem>
        <template #name>
          <div class="team-type-header" @click="displayTab() & changeTorneo('tercera')">
            <h4>TERCERA (TDP)</h4>
          </div>
        </template>

        <div class="tabla-liga">
        <div class="errore">
          <div class="en-construccion-cont">
              <img src="../assets/tarjeta-rojiza.png" alt="tarjetiza" class="tarjetiza">
              <h3 class="titulo-en-constr"><span class="red"> VUELVE EN UN RATO.</span> ESTAMOS CARGANDO LA INFORMACIÓN.</h3>
          </div>
          </div>
        </div>
      </TabItem>
    </Tabs>
  </div>
</template>

<script>
import { Tabs, TabItem } from "vue-material-tabs";
import Sponsor from "../components/widgets/SponsorSecW";
import TablaFirstTeam from "../components/TablaFirstTeam";
export default {
  components: {
    Tabs,
    TabItem,
    Sponsor,
    TablaFirstTeam,
  },
  data() {
     return {
      isActive: true,
      nombretorneo:'expansion',
      ligaexp: [{torneo:2, temporada: 72}],
      to: 2,
      te: 72,
      C22: [{torneo:2, temporada: 72}],
      A21: [{torneo:1, temporada: 72}],
      C21: [{torneo:2, temporada: 71}],
      A20: [{torneo:1, temporada: 71}],
      C20: [{torneo:2, temporada: 70}],
      A19: [{torneo:1, temporada: 70}],
    }
  },
  mounted() {
    if (localStorage.ligaexp) {
      this.ligaexp = JSON.parse(localStorage.getItem('ligaexp'));
    }
  },
  watch: {
    ligaexp(newligaexp) {
      localStorage.ligaexp = JSON.stringify(newligaexp);
    }
  },
  methods:{
    changeTorneo(nombre){
      if(nombre=='expansion'){
        this.nombretorneo = 'expansion'
      }else
      if(nombre=='premier'){
        this.nombretorneo = 'premier'
      }else
      if(nombre=='tercera'){
        this.nombretorneo = 'tercera'
      }
    },
    displayTab(){
      document.getElementById('tabactiva').classList.remove('activa');
      document.getElementById('primer-equipo').style.display = 'none!important';
      document.getElementById('primer-equipo').style.maxHeight = 0;
      document.getElementById('primer-equipo').style.maxWidth = 0;
    },
    alturaMaxima(){
      document.getElementById('primer-equipo').style.maxHeight = 'fit-content';
      document.getElementById('primer-equipo').style.maxWidth = 'fit-content';
    },
  }
    
}
</script>

<style scoped>

#primer-equipo {
    display: flex!important;
}#calendario.cla .tabla-liga {
    padding: 2vw 0;
    width: 88vw;
}.team-type-header {
    width: 100%;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
}.activa {
    color: white!important;
    background: #B70D1D;
    padding-right: 5vw!important;
    background-size: 2.865vw!important;
    background-position-x: 10.115vw!important;
    background-position-y: center;
    background-repeat: no-repeat;
    display: flex;
    background-image: url('../assets/arrow2.png')!important;
}.errore {
    margin: auto;
}.cla-select {
    position: relative;
    z-index: 999;
    border: none;
    width: 10vw;
    font-size: 1vw;
    font-family: 'Rajdhani';
    color: gray;
}

@media (max-width: 768px) {
#calendario.cla .tabla-liga {
    width: 98vw;
}.activa {
    padding-right: 0!important;
}
}
</style>