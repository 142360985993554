<template >
  <div id="calendario" class="cla">
    <div id="headerHome" class="col-sm-12 col-md-12 color-bg-1">
      <HeaderHome :name="user.fullname" />
    </div>

    <div class="clas-body">

      <ClasificacionTab />

    </div>

    <div id="footerSection">
      <Footer />
    </div>
  </div>
</template>


<script>
import Footer from "../components/footer";
import HeaderHome from "../components/HeaderHome";
import ClasificacionTab from "../components/ClasificacionTab";

export default {
  components: {
    HeaderHome,
    Footer,
    ClasificacionTab,
  },
  async beforeMount() {
    let token = await this.$store.dispatch("admin/getToken");
    this.$store.dispatch("admin/decode", token);
  },
  computed: {
    user() {
      return this.$store.getters["admin/getIdentity"];
    },
  },


  /**titulo pagina */
  watch: {
        $route: {
            immediate: true,
            handler(to, from) {
                document.title = to.meta.title || 'Clasificaciones - Cimarrones de Sonora';
            }
        },
    }
};
</script>