<template>
  <div class="tabla-liga">
    <div class="select-cont">
      <div class="temp-sem-liga">
        <h4>Temporada {{temptext}}</h4>
        
        <div  class="cla-select">
          {{nomtext}} <img src="../assets/grayeq.png" alt="tr" class="eqt">
          <ul>
           <!-- <li  @click="ligaexp = A24" :value="A24"><a>Apertura 24</a></li> -->
            <li  @click="ligaexp = C24" :value="C24"><a>Clausura 24</a></li>
            <li  @click="ligaexp = A23" :value="A23"><a>Apertura 23</a></li>
            <li  @click="ligaexp = C23" :value="C23"><a>Clausura 23</a></li>
            <li  @click="ligaexp = A22" :value="A22"><a>Apertura 22</a></li>
            <li  @click="ligaexp = C22" :value="C22"><a>Clausura 22</a></li>
            <li  @click="ligaexp = A21" :value="A21"><a>Apertura 21</a></li>
            <li  @click="ligaexp = C21" :value="C21"><a>Clausura 21</a></li>
            <li  @click="ligaexp = A20" :value="A20"><a>Apertura 20</a></li>
            <li  @click="ligaexp = C20" :value="C20"><a>Clausura 20</a></li>
            <li  @click="ligaexp = A19" :value="A19"><a>Apertura 19</a></li>
          </ul>
        </div>
        <!--
        <select  class="cla-select" v-model="ligaexp">
          <option selected :value="C22">Clausura 22</option>
          <option :value="A21">Apertura 21</option>
          <option :value="C21">Clausura 21</option>
          <option :value="A20">Apertura 20</option>
          <option :value="C20">Clausura 20</option>
          <option :value="A19">Apertura 19</option>
        </select>
        -->
      </div>
    </div>
    <div class="dots-meaning">
      <h4 class="mean">
        <div class="dotite verde"></div>
        Clasificado
      </h4>
      <h4 class="mean">
        <div class="dotite rojo"></div>
        Reclasificado Local
      </h4>
      <h4 class="mean">
        <div class="dotite azul"></div>
        Reclasificado Visita
      </h4>
    </div>
    <table style="width: 100%">
      <tr class="titleelements">
        <th>Pos</th>
        <th class="teamth">Equipo</th>
        <th>PtsT</th>
        <th>PJ</th>
        <th>V</th>
        <th>E</th>
        <th>D</th>
        <th class="mobile">GF</th>
        <th class="mobile">GC</th>
        <th>DG</th>
      </tr>
      <tr class="elements" v-for="(club, key) in tabla" :key="key">
        <td
          v-if="club.nombreClub == 'Cimarrones'"
          class="position-tb isgold"
          :style="{ borderLeft: activeCla[club.lugarTorneo] }"
        >
          {{ club.lugarTorneo }}
        </td>
        <td
          v-else
          class="position-tb"
          :style="{ borderLeft: activeCla[club.lugarTorneo] }"
        >
          {{ club.lugarTorneo }}
        </td>

        <td
          v-if="club.nombreClub == 'Cimarrones'"
          class="name-club-tb isred"
          id="isred"
        >
          <img src="../assets/Cimarrones-mx.png" alt="logo" class="logo-td" />
          <p class="pc">{{ club.nombreClub }}</p>
          <p class="mo">{{ nameClub(club.nombreClub) }}</p>
        </td>
        <td
          v-else-if="club.lugarTorneo % 2 == 0"
          class="name-club-tb"
          id="the-td"
        >
          <img :src="imageLogo(club.nombreClub)" alt="logo" class="logo-td" />
          <p class="pc">{{ club.nombreClub }}</p>
          <p class="mo">{{ nameClub(club.nombreClub) }}</p>
        </td>
        <td v-else class="name-club-tb isgray" id="the-td">
          <img :src="imageLogo(club.nombreClub)" alt="logo" class="logo-td" />
          <p class="pc">{{ club.nombreClub }}</p>
          <p class="mo">{{ nameClub(club.nombreClub) }}</p>
        </td>

        <td v-if="club.nombreClub == 'Cimarrones'" class="pts-tb" id="isred">
          {{ club.puntos + club.puntosExtra }}
        </td>
        <td v-else-if="club.lugarTorneo % 2 == 0" class="pts-tb" id="the-td">
          {{ club.puntos + club.puntosExtra }}
        </td>
        <td v-else class="pts-tb isgray" id="the-td">
          {{ club.puntos + club.puntosExtra }}
        </td>

        <td v-if="club.nombreClub == 'Cimarrones'" id="isred">{{ club.jj }}</td>
        <td v-else-if="club.lugarTorneo % 2 == 0" id="the-td">{{ club.jj }}</td>
        <td v-else class="isgray" id="the-td">{{ club.jj }}</td>

        <td v-if="club.nombreClub == 'Cimarrones'" id="isred">{{ club.jg }}</td>
        <td v-else-if="club.lugarTorneo % 2 == 0" id="the-td">{{ club.jg }}</td>
        <td v-else class="isgray" id="the-td">{{ club.jg }}</td>

        <td v-if="club.nombreClub == 'Cimarrones'" id="isred">{{ club.je }}</td>
        <td v-else-if="club.lugarTorneo % 2 == 0" id="the-td">{{ club.je }}</td>
        <td v-else class="isgray" id="the-td">{{ club.je }}</td>

        <td v-if="club.nombreClub == 'Cimarrones'" id="isred">{{ club.jp }}</td>
        <td v-else-if="club.lugarTorneo % 2 == 0" id="the-td">{{ club.jp }}</td>
        <td v-else class="isgray" id="the-td">{{ club.jp }}</td>

        <td v-if="club.nombreClub == 'Cimarrones'" id="isred" class="mobile">
          {{ club.gf }}
        </td>
        <td v-else-if="club.lugarTorneo % 2 == 0" id="the-td" class="mobile">
          {{ club.gf }}
        </td>
        <td v-else class="isgray mobile" id="the-td">{{ club.gf }}</td>

        <td v-if="club.nombreClub == 'Cimarrones'" id="isred" class="mobile">
          {{ club.gc }}
        </td>
        <td v-else-if="club.lugarTorneo % 2 == 0" id="the-td" class="mobile">
          {{ club.gc }}
        </td>
        <td v-else class="isgray mobile" id="the-td">{{ club.gc }}</td>

        <td v-if="club.nombreClub == 'Cimarrones'" id="isred">
          {{ club.diferencia }}
        </td>
        <td v-else-if="club.lugarTorneo % 2 == 0" id="the-td">
          {{ club.diferencia }}
        </td>
        <td v-else class="isgray" id="the-td">{{ club.diferencia }}</td>
      </tr>
    </table>
    <div class="meaning-tabla-cont">
      <div class="col-cont">
        <h3 class="meaning-tabla">
          <span class="blue">PtsT</span>
          Puntos Totales (Incluye puntos extra)
        </h3>
        <h3 class="meaning-tabla">
          <span class="blue">PJ</span>
          Partidos Jugados
        </h3>
        <h3 class="meaning-tabla">
          <span class="blue">V</span>
          Victorias
        </h3>
        <h3 class="meaning-tabla">
          <span class="blue">E</span>
          Empates
        </h3>
        <h3 class="meaning-tabla">
          <span class="blue">D</span>
          Derrotas
        </h3>
      </div>
      <div class="col-cont">
        <h3 class="meaning-tabla">
          <span class="blue">GF</span>
          Goles a Favor
        </h3>
        <h3 class="meaning-tabla">
          <span class="blue">GC</span>
          Goles en Contra
        </h3>
        <h3 class="meaning-tabla">
          <span class="blue">DG</span>
          Diferencia de Goles
        </h3>
      </div>
    </div>
  </div>
</template>

<script>
import { mapActions } from "vuex";
import ClasificacionTab from "../components/ClasificacionTab.vue";

export default {
  name: "TablaGeneral",

  data() {
    return {
      status: "",
      msg: "",
      ligaexp: [{ division: 20, torneo: 2, temporada: 74, temp: '2023/2024', nom: 'Clausura 24' }],
     // A24: [{ division: 20, torneo: 1, temporada: 75, temp: '2024/2025', nom: 'Apertura 24' }],
      C24: [{ division: 20, torneo: 2, temporada: 74, temp: '2023/2024', nom: 'Clausura 24' }],
      A23: [{ division: 20, torneo: 1, temporada: 74, temp: '2023/2024', nom: 'Apertura 23' }],
      C23: [{ division: 20, torneo: 2, temporada: 73, temp: '2022/2023', nom: 'Clausura 23' }],
      A22: [{ division: 20, torneo: 1, temporada: 73, temp: '2022/2023', nom: 'Apertura 22' }],
      C22: [{ division: 2, torneo: 2, temporada: 72, temp: '2021/2022', nom: 'Clausura 22' }],
      A21: [{ division: 2, torneo: 1, temporada: 72, temp: '2021/2022', nom: 'Apertura 21' }],
      C21: [{ division: 2, torneo: 2, temporada: 71, temp: '2020/2021', nom: 'Clausura 21' }],
      A20: [{ division: 2, torneo: 1, temporada: 71, temp: '2020/2021', nom: 'Apertura 20' }],
      C20: [{ division: 2, torneo: 2, temporada: 70, temp: '2019/2020', nom: 'Clausura 20' }],
      A19: [{ division: 2, torneo: 1, temporada: 70, temp: '2019/2020', nom: 'Apertura 19' }],
      ligaexpU: [],
      divisionSe: 0,
      temporadaSe: 0,
      torneoSe: 0,
      divisionUSe: 0,
      temporadaUSe: 0,
      torneoUSe: 0,
      textotmp: '',
      /*activeCla:false,
      activeRL:false,
      activeRV:false,
      activeFue:true,*/
      activeCla: {
        1: "0.521vw solid #64EC61!important",
        2: "0.521vw solid #64EC61!important",
        3: "0.521vw solid #64EC61!important",
        4: "0.521vw solid #64EC61!important",
        5: "0.521vw solid #FF3D00!important",
        6: "0.521vw solid #FF3D00!important",
        7: "0.521vw solid #FF3D00!important",
        8: "0.521vw solid #FF3D00!important",
        9: "0.521vw solid #44D2FF!important",
        10: "0.521vw solid #44D2FF!important",
        11: "0.521vw solid #44D2FF!important",
        12: "0.521vw solid #44D2FF!important",
        13: "0.521vw solid white!important",
        14: "0.521vw solid white!important",
        15: "0.521vw solid white!important",
        16: "0.521vw solid white!important",
        17: "0.521vw solid white!important",
      },
    };
  },
  updated() {
    if (this.ligaexp != this.ligaexpU) {
      this.ligaexpU = this.ligaexp;
      let payload = {
        idDivision: this.divisionSe,
        idTemporada: this.temporadaSe,
        idTorneo: this.torneoSe,
      };
      this.getTablaGeneralDimanica(payload);
    }
  },
  async created() {
    let payload = {
      idDivision: this.divisionSe,
      idTemporada: this.temporadaSe,
      idTorneo: this.torneoSe,
    };
    await this.getTablaGeneralDimanica(payload);
    await this.getTournaments2();
  },
  computed: {
    url() {
      return this.$store.getters["main/baseURL"];
    },
    torneos() {
      return this.$store.getters["tournament/data2"];
    },
    tabla() {
      return this.$store.getters["tablaGeneral/data2"];
    },
    ligaexp(newligaexp) {
      localStorage.ligaexp = JSON.stringify(newligaexp);
      return localStorage.ligaexp;
    },
    divisionMx() {
      let temp = this.ligaexp[0].division;
      this.divisionSe = temp;
      this.divisionUSe = temp;
      return temp;
    },
    temporadaMx() {
      let temp = this.ligaexp[0].temporada;
      this.temporadaSe = temp;
      this.temporadaUSe = temp;
      return temp;
    },
    torneoMx() {
      let temp = this.ligaexp[0].torneo;
      this.torneoSe = temp;
      this.torneoUSe = temp;
      return temp;
    },
    temptext() {
      let temp = this.ligaexp[0].temp;
      this.textotemp = temp;
      return temp;
    },
    nomtext() {
      let temp = this.ligaexp[0].nom;
      this.textonom = temp;
      return temp;
    },
  },
  mounted() {
    if (localStorage.ligaexp) {
      this.ligaexp = JSON.parse(localStorage.getItem("ligaexp"));
    }
  },
  watch: {
    async ligaexp(newligaexp) {
      localStorage.ligaexp = JSON.stringify(newligaexp);
      let data = localStorage.ligaexp;
      this.ligaexpU = data;
      await data;
    },
    async divisionMx() {
      let temp = this.ligaexp[0].division;
      this.divisionSe = temp;
      this.divisionUSe = temp;
      return temp;
    },
    async temporadaMx() {
      let temp = this.ligaexp[0].temporada;
      this.temporadaSe = temp;
      this.temporadaUSe = temp;
      await temp;
    },
    async torneoMx() {
      let tor = this.ligaexp[0].torneo;
      this.torneoSe = tor;
      this.torneoUSe = tor;
      await tor;
    },
    async temptext() {
      let temp = this.ligaexp[0].temp;
      this.textotmp = temp;
       await temp;
    },
    async nomtext() {
      let temp = this.ligaexp[0].nom;
      this.textonom = temp;
       await temp;
    },
  },
  methods: {
    ...mapActions("tablaGeneral", ["getTablaGeneralDimanica"]),
    ...mapActions("tournament", ["getTournaments2"]),

    getTournaments2: async function () {
      let result = await this.$store.dispatch("tournament/getTournaments2", {
        option: "torneos",
      });
      this.info = result;
      return result;
    },

    clasificacionMet(posicion) {
      if (posicion == 1 && posicion == 2 && posicion == 3 && posicion == 4) {
        this.activeCla = true;
        this.activeFue = false;
      }
      if (posicion == 5 && posicion == 6 && posicion == 7 && posicion == 8) {
        this.activeRL = true;
        this.activeFue = false;
      }
      if (posicion == 9 && posicion == 10 && posicion == 11 && posicion == 12) {
        this.activeRV = true;
        this.activeFue = false;
      }
    },

    imageLogo: function (opname) {
      if (opname == "Dorados de Sinaloa") {
        return require("../assets/Dorados-mx.png");
      } else if (opname == "Atlante") {
        return require("../assets/Atlante-mx.png");
      } else if (opname == "Club Atlético Morelia") {
        return require("../assets/Altetico-Morelia-mx.png");
      } else if (opname == "Pumas Tabasco") {
        return require("../assets/Pumas-mx.png");
      } else if (opname == "U. de G.") {
        return require("../assets/Leones-Negros-mx.png");
      } else if (opname == "Club Celaya") {
        /* else if (opname == "Cimarrones") {
        return "MOR";
      } */
        return require("../assets/Celaya-mx.png");
      } else if (opname == "Venados") {
        return require("../assets/Venados-mx.png");
      } else if (opname == "TM Futbol Club") {
        return require("../assets/TM-mx.png");
      } else if (opname == "RAYA2 EXPANSIÓN ") {
        return require("../assets/raya2.png");
      } else if (opname == "Mineros de Zacatecas") {
        return require("../assets/Mineros-mx.png");
      } else if (opname == "Tlaxcala F.C.") {
        return require("../assets/Tlaxcala-mx.png");
      } else if (opname == "Tepatitlán FC") {
        return require("../assets/Tepatitlan-mx.png");
      } else if (opname == "Cancún FC") {
        return require("../assets/Cancun-mx.png");
      } else if (opname == "Tapatío") {
        return require("../assets/Tapatio-mx.png");
      } else if (opname == "Correcaminos") {
        return require("../assets/Correcaminos-mx.png");
      } else if (opname == "Alebrijes de Oaxaca") {
        return require("../assets/Alebrijes-mx.png");
      } else {
        return require("../assets/na-logo.png");
      }
    },

    nameClub: function (opname) {
      if (opname == "Dorados de Sinaloa") {
        return "DOR";
      } else if (opname == "Atlante") {
        return "ATL";
      } else if (opname == "Club Atlético Morelia") {
        return "MOR";
      } else if (opname == "Pumas Tabasco") {
        return "PUM";
      } else if (opname == "U. de G.") {
        return "UDG";
      } else if (opname == "Cimarrones") {
        return "CIM";
      } else if (opname == "Club Celaya") {
        return "CEL";
      } else if (opname == "Venados") {
        return "VEN";
      } else if (opname == "TM Futbol Club") {
        return "TAM";
      } else if (opname == "RAYA2 EXPANSIÓN ") {
        return "RAY";
      } else if (opname == "Mineros de Zacatecas") {
        return "MIN";
      } else if (opname == "Tlaxcala F.C.") {
        return "TLX";
      } else if (opname == "Tepatitlán FC") {
        return "TEP";
      } else if (opname == "Cancún FC") {
        return "CAN";
      } else if (opname == "Tapatío") {
        return "TAP";
      } else if (opname == "Correcaminos") {
        return "UAT";
      } else if (opname == "Alebrijes de Oaxaca") {
        return "OAX";
      }
    },
  },
};
</script>

<style scoped>
.isgray {
  background: #f0f0f0;
}
#isred {
  background: rgb(185, 21, 21) !important;
  color: white !important;
}
.isgold {
  background: #8B101B !important;
}
img.logo-td {
  width: 2.708vw;
  height: auto;
  margin-right: 3vw;
}

.dots-meaning {
    display: flex;
    width: 36.458vw;
    justify-content: space-between;
    top: -2vw;
    position: relative;
}
.dots-meaning .mean {
  font-size: 0.938vw;
  line-height: 160.49%;
  color: #032a4b;
  font-family: "Rajdhani";
  display: flex;
  align-items: center;
  margin: 0;
}
.dots-meaning .dotite {
  width: 1vw;
  height: 1vw;
  border-radius: 1vw;
  margin-right: 0.3vw;
}
.dots-meaning .dotite.verde {
  background-color: #64ec61;
}
.dots-meaning .dotite.rojo {
  background-color: #ff3d00;
}
.dots-meaning .dotite.azul {
  background-color: #44d2ff;
}

.meaning-tabla-cont {
  display: flex;
  padding: 3.802vw 0;
}
.meaning-tabla-cont .col-cont {
  display: flex;
}
.meaning-tabla-cont .meaning-tabla {
  font-size: 0.938vw;
  font-family: "Rajdhani";
  padding-right: 2vw;
}
.meaning-tabla-cont .meaning-tabla .blue {
  font-family: "Rajdhani";
  color: #032a4b;
}
.select-cont {
  position: relative;
  left: 72vw;
  top: 1vw;
}
.cla-select {
  position: relative;
  z-index: 999;
  border: none;
  width:8vw;
  font-size: 1.1vw;
  font-family: "Rajdhani";
  color: gray;
 /**background-color: white !important;
  background-image: url('../assets/grayeq.png');
  background-repeat: no-repeat;
  background-size: 1.2vw;
  background-position-x: right;
  background-position-y: center; */ 
  cursor: default;
}.eqt{
  width: .7vw;
  margin-left: .5vw;
}
.cla-select > ul { display: none; }
.cla-select:hover > ul {
display: block; 
background-color: #f9f9f9;
width: 13.719vw;
z-index: 999;}
.cla-select:hover > ul > li { padding: .5vw 1.875vw;height: 2.396vw;}
.cla-select:hover > ul > li:hover { background-color: rgb(185, 21, 21) !important;
background-image: url('../assets/droparr.png');
background-size: 2.865vw;
background-repeat: no-repeat;
background-position-x: 9vw;
background-position-y: center;}
.cla-select:hover > ul > li:hover > a { color: white; }


.pc {
  display: flex;
}
.mo {
  display: none;
}
@media (max-width: 768px) {
  .pc {
    display: none;
  }
  .mo {
    display: flex;
  }
  .mobile {
    display: none !important;
  }
  img.logo-td {
    width: 6.708vw;
  }
  .dots-meaning {
    width: 100%;
    flex-direction: column;
    margin-left: 3vw;
    top: 0;
  }
  .dots-meaning .mean {
    font-size: 2.938vw;
  }
  .dots-meaning .dotite {
    width: 3vw;
    height: 3vw;
    border-radius: 3vw;
    margin-right: 3.3vw;
  }
  th.teamth {
    width: 27vw !important;
  }

  .meaning-tabla-cont {
    padding: 9.802vw 0;
  }
  .meaning-tabla-cont .col-cont {
    display: flex;
    flex-direction: column;
  }
  .meaning-tabla-cont .meaning-tabla {
    font-size: 2.938vw;
    margin-bottom: 6vw;
  }
  .meaning-tabla-cont {
    justify-content: space-around;
  }
  .cla-select {
    width: 27vw;
    font-size: 3.1vw;
    height: 8vw;
  }
  .select-cont {
    left: 60vw;
    top: 8vw;
  }
.cla-select:hover > ul {
display: block; 
background-color: #f9f9f9;
width: 30.719vw;
z-index: 999;
margin-left: -2vw;}
.cla-select:hover > ul > li { padding: .5vw 1.875vw;height: 5.396vw;font-size: 3vw;}
.cla-select:hover > ul > li:hover { background-color: rgb(185, 21, 21) !important;
background-image: url('../assets/droparr.png');
background-size: 6.865vw;
background-repeat: no-repeat;
background-position-x: 21vw;
background-position-y: center;}
.eqt{
  width: 1.8vw;
  margin-left: .5vw;
}}

</style>