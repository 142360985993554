<template>
<div>
  <div class="patro-u-n" >
    <p>PATROCINADOR OFICIAL</p>
    <a :href="sponsordata.url" target="_blank" rel="noopener noreferrer">
    <img :src="url+'/get-sponsor-image/'+isWebp(regexImg(sponsordata.image), this.sponsordata.image) " alt="sponsor" width="145" height="38" /></a>
  </div>
</div>
</template>

<script>
import {mapActions} from 'vuex';
export default {
  created() {
    this.findByName4();
  },
  computed: {
    url() {
      return this.$store.getters["main/baseURL"];
    },
    sponsordata() {
      return this.$store.getters["sponsor/data4"];
    },
  },
  methods: {
    ...mapActions('sponsor', ['findByName4']), 

        regexImg(txt){
          let s = txt.split('.')
          return s[0]+'.webp'

        },
    isWebp(webpImg, pngImg) {
      let ua = navigator.userAgent.toLowerCase();
            if (ua.indexOf("chrome") > -1) {
              return webpImg; // Chrome
            } else if (ua.indexOf("firefox") > -1) {
              return webpImg; // Firefox
            } else if (ua.indexOf("opera") > -1) {
              return webpImg; // Opera
            } else if (ua.indexOf("safari") > -1)  {
              return pngImg; // Safari
            }
    },
    isNav() {
      let ua = navigator.userAgent.toLowerCase();
            if (ua.indexOf("chrome") > -1) {
              return 'webp'; // Chrome
            } else if (ua.indexOf("firefox") > -1) {
              return 'webp'; // Firefox
            } else if (ua.indexOf("opera") > -1) {
              return 'webp'; // Opera
            } else if (ua.indexOf("safari") > -1)  {
              return ''; // Safari
            }
    },

    findByName4: async function() {
      let result = await this.$store.dispatch("sponsor/findByName4", {
        "option" : "sponsor/nombre", "name" : "NOTICIAS"
      });
      this.info = result;
      return result;
    },
  },
};
</script>
